
import { computed, defineComponent, reactive, toRefs } from "vue";
import useSpreadTableSIConfirgurationReport from "@/hooks/Reporting/views/useSpreadTableSIConfirgurationReport";

import useYear from "@/hooks/Reporting/useYear";
import useBuMakeTP from "@/hooks/Reporting/useBuMakeTP";
import { usetableHeight } from "@/views/System/NSTGroupMSRP/NSTGroupMSRP/utils";
import blobDownload from "@/utils/blobDownload";
import moment from "moment";
import useFindBu from "@/hooks/Reporting/useFindBu";
interface QueryParamsProps {
  bu: string;
  model: string[];
  typeClass: string[];
}
export default defineComponent({
  name: "SI Confirguration Report",
  setup() {
    const queryParams = reactive<QueryParamsProps>({
      bu: '',
      model: [],
      typeClass: [],
    });
    const { buList, findBu } = useFindBu();
    const { year, openYear, handlerOpenChange, handlerPanelChange } = useYear();
    // 给bu赋默认值
    queryParams.bu = findBu();
    const {
      handleFocus,
      typeClassOptions,
      modelOptions,
      resetObj,
      buNameIdMap,
    } = useBuMakeTP<QueryParamsProps>(queryParams, true);

    const buNameIdMapList = buNameIdMap();
    const buIds = computed(() => {
      return buNameIdMapList[queryParams.bu];
    });

    const {
      getFetch,
      dataRes: data,
      spinFlag,
    } = useSpreadTableSIConfirgurationReport(
      Object.assign({}, queryParams, {
        year: year.value,
        bu: buIds.value,
      })
    );

    const { tableHeight } = usetableHeight("#ssHost");

    const handlerSearchClick = (): void => {
      getFetch(
        Object.assign({}, queryParams, { year: year.value, bu: buIds.value })
      );
    };
    const handlerReset = (): void => {
      Object.assign(queryParams, resetObj);
      year.value = moment().add(1, "month").format("YYYY");
    };
    const handlerDownClick = () => {
      blobDownload({
        url: "/pgapi/report/export_si_configuration",
        params: Object.assign(queryParams, { year: year.value }),
      });
    };

    return {
      modelOptions,
      typeClassOptions,
      handleFocus,
      ...toRefs(queryParams),
      year,
      openYear,
      handlerOpenChange,
      handlerPanelChange,
      handlerReset,
      handlerSearchClick,
      data,
      handlerDownClick,
      tableHeight,
      buList,
      spinFlag,
    };
  },
});
