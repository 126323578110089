import { ref } from 'vue';
import moment from "moment";
export default function useYear() {
    // 年份
    const year = ref<string>(moment().add('month', 1).format('YYYY'));
    const openYear = ref(false);
    const handlerOpenChange = (status: boolean): void => {
        openYear.value = status;
    };
    const handlerPanelChange = (value: string): void =>{
        const time = moment(value).format('YYYY');
        year.value = time as string;
        openYear.value = false;
    }
    return {
        year,
        openYear,
        handlerOpenChange,
        handlerPanelChange
    }
}