import GC from '@grapecity/spread-sheets'
import { reactive, toRefs, onMounted,  } from 'vue';
import { HeaderData, NstGroupData, MsrpData } from '../types'
import { getMonth } from '@/utils'
import { message } from 'ant-design-vue'
interface TablePosition {
    tableHeight: number;
}

interface SaveData {
    make?: any;
    id: string;
    overrideValue: number;
    version?: string;
    year?: string;
    month: string;
    brand: string | null;
    typeClass: string | null;
    nstGroup: string | null;    
    nstGroupName: string | null;    
}

// 当前月
const currentMonth = getMonth();

let tableData: NstGroupData[];

export let saveData: SaveData[] = [];

// 表格确定列数量
const baseColCount = 4;

const columnsWidth = {
    'Brand': 70,
    'Type Class': 85,
    'NST Group': 150,
    'NST Group Name': 170,
}

const monthHeader = [{
    displayName: 'Jan',
},{
    displayName: 'Feb',
},{
    displayName: 'Mar',
},{
    displayName: 'Apr',
},{
    displayName: 'May',
},{
    displayName: 'Jun',
},{
    displayName: 'Jul',
},{
    displayName: 'Aug',
},{
    displayName: 'Sep',
},{
    displayName: 'Oct',
},{
    displayName: 'Nov',
},{
    displayName: 'Dec',
}]

const monthMaps = {
    jan: '1',
    feb: '2',
    mar: '3',
    apr: '4',
    may: '5',
    jun: '6',
    jul: '7',
    aug: '8',
    sep: '9',
    oct: '10',
    nov: '11',
    dec: '12'
}

const monthKeys = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

const getFirstHeader = (allYear: number) => {
    const baseHeader = [{
        displayName: 'Vehicle',
    },{
        displayName: 'Vehicle',
    },{
        displayName: 'Vehicle',
    },{
        displayName: 'Vehicle',
    }];
    const pastMonth = monthHeader.slice(0, allYear)
    const futureMonth = monthHeader.slice(allYear)
    debugger
    const doubleFutureMonth = futureMonth.map((item) => {
        return [item, item]
    }).flat()

    return baseHeader.concat(pastMonth, doubleFutureMonth)    
}

const getSecondHeader = (allYear: number) => {
    const baseHeader = [{
        displayName: 'Brand',
    },{
        displayName: 'Type Class',
    },{
        displayName: 'NST Group',
    },{
        displayName: 'NST Group Name',
    }];
    const pastMonth = monthHeader.slice(0, allYear).map((item) => {
        return {
            displayName: ''
        }
    })

    const futureMonth = monthHeader.slice(allYear)
    
    const doubleFutureMonth = futureMonth.map(() => {
        return [{
            displayName: 'MSRP',
        },{
            displayName: 'Override',
        }]
    }).flat()
    return baseHeader.concat(pastMonth, doubleFutureMonth)    
}

const getDataKeys = (allYear: number) => {
    const baseKeys = ['brand', 'typeClass', 'nstGroup', 'nstGroupName'];
    const pastMonthKeys = monthKeys.slice(0, allYear)
    const futureMonth = monthKeys.slice(allYear)
    
    const doubleFutureMonthKeys = futureMonth.map((item) => {
        return [item, item]
    }).flat()

    return baseKeys.concat(pastMonthKeys, doubleFutureMonthKeys)    
}

// const firstHeader = getFirstHeader()
// const secondHeader = getSecondHeader()
// const dataKeys: string[] = getDataKeys()
// 设置表格的头部，可以不占用单元格
// const header: HeaderData[][] = [
//     firstHeader, secondHeader
// ]

// 设置列宽度
const setColumnWidth = (sheet: GC.Spread.Sheets.Worksheet, secondHeader: {displayName: string}[]) => {
    secondHeader.forEach((item, index) => {
        if (!columnsWidth[item.displayName]) {
            sheet.setColumnWidth(index, 120)
        } else {
            sheet.setColumnWidth(index, columnsWidth[item.displayName])
        }
    })
}

const setFilterButtonVisible = (rowFilter: GC.Spread.Sheets.Filter.HideRowFilter, dataKeys: string[]) => {
    for (let keyInd = 0; keyInd < dataKeys.length; keyInd++) { 
        if (keyInd > baseColCount || keyInd === baseColCount)
        rowFilter.filterButtonVisible(keyInd, false)
    }
}

// 设置表头
export const setHeader = (spread: GC.Spread.Sheets.Workbook, allYear: number ) => {
    console.log(allYear, 'allYear')
    const sheet = spread.getActiveSheet();
    //挂起
    sheet.suspendPaint();

    //设置header行数为2行
    sheet.setRowCount(2, GC.Spread.Sheets.SheetArea.colHeader);

    // 文字换行
    // const row = sheet.getRange(-1, -1, -1, -1, GC.Spread.Sheets.SheetArea.viewport);
    // row.wordWrap(true);
    const header: HeaderData[][] = [
        getFirstHeader(allYear), getSecondHeader(allYear)
    ] 
    setColumnWidth(sheet, getSecondHeader(allYear));
    sheet.setColumnCount(getSecondHeader(allYear).length, GC.Spread.Sheets.SheetArea.viewport)
    const range = new GC.Spread.Sheets.Range(-1, 0, -1, getSecondHeader(allYear).length);
    const rowFilter = new GC.Spread.Sheets.Filter.HideRowFilter(range);
    sheet.rowFilter(rowFilter);
    rowFilter.filterDialogVisibleInfo({
        sortByColor: false,
    })
    const dataKeys: string[] = getDataKeys(allYear)
    setFilterButtonVisible(rowFilter, dataKeys)
    //set header 设置头的样式
    for (let row = 0; row < header.length; row++) {
        for (let cell = 0; cell < header[row].length; cell++) {
            sheet.setValue(row, cell, header[row][cell].displayName, GC.Spread.Sheets.SheetArea.colHeader);
            sheet.getCell(row, cell, GC.Spread.Sheets.SheetArea.colHeader).backColor('#bfbfbf')
        }
        // sheet.setRowHeight(row, 40, GC.Spread.Sheets.SheetArea.colHeader);
    }

    for (let index = 0; index < header[0].length; index++) {
        if (index > baseColCount - 1) {
            sheet.getRange(-1, index, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.right)
            // sheet.getRange(1, index, 1, 1, GC.Spread.Sheets.SheetArea.colHeader).hAlign(GC.Spread.Sheets.HorizontalAlign.right)
        } else {
            sheet.getRange(-1, index, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.left)
            // sheet.getRange(1, index, 1, 1, GC.Spread.Sheets.SheetArea.colHeader).hAlign(GC.Spread.Sheets.HorizontalAlign.left)

        }
    }
    sheet.getRange(-1, 0, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.center)
    sheet.getRange(-1, 1, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.center)

    // // 必须加上这两个才能折叠展开
    // sheet.options.protectionOptions.allowOutlineRows = true
    // sheet.options.protectionOptions.allowOutlineColumns = true
    // 自动合并
    const ranges = new GC.Spread.Sheets.Range(-1, -1, -1, -1);
    sheet.autoMerge(ranges, GC.Spread.Sheets.AutoMerge.AutoMergeDirection.row, GC.Spread.Sheets.AutoMerge.AutoMergeMode.free, GC.Spread.Sheets.SheetArea.colHeader);
    sheet.autoMerge(ranges, GC.Spread.Sheets.AutoMerge.AutoMergeDirection.column, GC.Spread.Sheets.AutoMerge.AutoMergeMode.free, GC.Spread.Sheets.SheetArea.rowHeader);
    //重绘
    sheet.resumePaint();
}



// 绑定数据
export const bindDataTable = (spread: GC.Spread.Sheets.Workbook, data: NstGroupData[], allYear: number) => {
    tableData = data;
    // 清空之前的saveData
    saveData = [];
    // 挂起
    spread.suspendPaint();
    const sheet = spread.getActiveSheet();
    const spreadNS = GC.Spread.Sheets;
    // 设置多少行和列
    sheet.setRowCount(data.length, GC.Spread.Sheets.SheetArea.viewport)
    // sheet.setColumnCount(secondHeader.length, GC.Spread.Sheets.SheetArea.viewport)
    const canEdit = new spreadNS.Style();
    canEdit.locked = false;

    // const range = new GC.Spread.Sheets.Range(-1, 0, -1, secondHeader.length);
    // const rowFilter = new GC.Spread.Sheets.Filter.HideRowFilter(range);
    // sheet.rowFilter(rowFilter);
    // rowFilter.filterDialogVisibleInfo({
    //     sortByColor: false,
    // })

    // setFilterButtonVisible(rowFilter)

    const fontColor = new spreadNS.Style();
    fontColor.foreColor = 'red'
    fontColor.locked = false;
    const dataKeys = getDataKeys(allYear)
    console.log(dataKeys);
    // 设置表单数据和样式
    data.forEach((item, rowIndex) => {
        let isMsrp = true;
        for (let keyInd=0; keyInd<dataKeys.length; keyInd++) {
            if (keyInd < baseColCount) {
                // 当前月份之前的月份对应第二行有一列数据
                sheet.setValue(rowIndex, keyInd, item && item[dataKeys[keyInd]], GC.Spread.Sheets.SheetArea.viewport);
                sheet.getCell(rowIndex, keyInd).backColor('#f0f0f0')
            } else if (keyInd < allYear + baseColCount) {
                // 当前月份之前的月份对应第二行有一列数据
                sheet.setValue(rowIndex, keyInd, item && item[dataKeys[keyInd]], GC.Spread.Sheets.SheetArea.viewport);
                // 数据格式设置
                // sheet.setFormatter(rowIndex, keyInd, "#,##0.00", spreadNS.SheetArea.viewport);
                sheet.setFormatter(rowIndex, keyInd, "#,##", spreadNS.SheetArea.viewport);
                sheet.getCell(rowIndex, keyInd).backColor('#f0f0f0')
            } else {
                
                // 当前月份以及之后月份对应第二行有两列数据
                if (isMsrp) {
                    const msrp = item && item[dataKeys[keyInd]] && (item[dataKeys[keyInd]] as MsrpData).msrp;
                    sheet.setValue(rowIndex, keyInd, msrp, GC.Spread.Sheets.SheetArea.viewport);
                    sheet.getCell(rowIndex, keyInd).backColor('#f0f0f0')
                    // 数据格式设置
                    // sheet.setFormatter(rowIndex, keyInd, "#,##0.00", spreadNS.SheetArea.viewport);
                    sheet.setFormatter(rowIndex, keyInd, "#,##", spreadNS.SheetArea.viewport);
                    isMsrp = false;
                } else {                   
                    const override = item && item[dataKeys[keyInd]] && (item[dataKeys[keyInd]] as MsrpData).override
                    sheet.setValue(rowIndex, keyInd, override, GC.Spread.Sheets.SheetArea.viewport);

                    // 设置单元格可编辑
                    sheet.setStyle(rowIndex, keyInd, canEdit);

                    
                    const status = item && item[dataKeys[keyInd]] && (item[dataKeys[keyInd]] as MsrpData).status                    
               
                    // 设置单元格字体颜色                    
                    if (status !== '2' && override) {
                        sheet.setStyle(rowIndex, keyInd, fontColor);
                        // sheet.getCell(2, 19).foreColor('red');
                    } 
                    
                    // 数据格式设置（放在setStyle前面不生效）
                    // sheet.setFormatter(rowIndex, keyInd, "#,##0.00", spreadNS.SheetArea.viewport);
                    sheet.setFormatter(rowIndex, keyInd, "#,##", spreadNS.SheetArea.viewport);

                    isMsrp = true; 
                    sheet.getCell(rowIndex, keyInd).setBorder( new spreadNS.LineBorder( '#d4d4d4' , spreadNS.LineStyle.thin),
                        { all: true }
                    );
                }    
                
                
            }
        }
        sheet.setRowHeight(rowIndex, 40, GC.Spread.Sheets.SheetArea.viewport);
        sheet.autoFitRow(rowIndex);
        // 隔行变色
        // (rowIndex%2 === 1) && sheet.getRange(rowIndex, -1, 1, -1, GC.Spread.Sheets.SheetArea.viewport).backColor('#f9f9f9')

        if (!item.brand || !item.typeClass) {
            sheet.getRange(rowIndex, -1, 1, -1, GC.Spread.Sheets.SheetArea.viewport).backColor('#ffff00')
        }
    })
       
    // 设置冻结列
    sheet.frozenColumnCount(baseColCount)

    spread.resumePaint();
}



export const initSpread = (spread: GC.Spread.Sheets.Workbook, allYear: number) => {
    spread.suspendPaint();
    const sheet = spread.getActiveSheet();
    const spreadNS = GC.Spread.Sheets;
    // 过滤
    // const range = new GC.Spread.Sheets.Range(-1, 0, -1, 18);
    // const rowFilter = new GC.Spread.Sheets.Filter.HideRowFilter(range);
    // sheet.rowFilter(rowFilter);
    // rowFilter.filterDialogVisibleInfo({
    //     sortByColor: false,
    // })
    // 设置单元格宽高
    // sheet.defaults.rowHeight = 40;
    // 设置列宽度
    // setColumnWidth(sheet);

    // 设置列数
    // sheet.setColumnCount(firstHeader.length);

    // 设置隐藏列头
    sheet.options.rowHeaderVisible = false
    // sheet.options.colHeaderVisible = true
    // sheet.options.clipBoardOptions = spreadNS.ClipboardPasteOptions.values;

    // sheet是否显示x
    spread.options.tabNavigationVisible = false
    spread.options.tabStripVisible = false
    
    //填充铺满整个canvas
    spread.options.scrollbarMaxAlign = true;
    spread.options.scrollByPixel = true;

    // 要设置允保护，不允许的才能生效
    sheet.options.isProtected = true;
    // 不允许单个格子编辑，是样式控制
    const sheetStyle = sheet.getDefaultStyle();
    //修改并设置表的默认样式locked为false.
    sheetStyle.locked = true;

    //默认对其方式
    sheetStyle.hAlign = spreadNS.HorizontalAlign.center;
    sheetStyle.vAlign = spreadNS.VerticalAlign.center;
    sheet.setDefaultStyle(sheetStyle);

    const fontColor = new spreadNS.Style();
    fontColor.foreColor = 'red'
    fontColor.locked = false;

    const fontBlackColor = new spreadNS.Style();
    fontBlackColor.foreColor = 'black'
    fontBlackColor.locked = false;
    const dataKeys = getDataKeys(allYear)
    const secondHeader = getSecondHeader(allYear)
    const setData = (args: any, type: boolean) => {
        let codeValue = ''
        if (type) {
            codeValue = sheet.getCell(args.row, args.col).value()
        } else {
            codeValue = args.newValue
        }
        
        if (isNaN(Number(args.newValue)) && !type) {
            message.error('请输入数字类型');
            sheet.resumeEvent();
            return;
        }
        const row = args.row;
        const col = args.col;
        if (allYear + baseColCount > col || row > tableData.length - 1 ) {
            return
        }
        const key = dataKeys[col]
        const rowData = tableData[row];
        const cellData = rowData[key] as MsrpData
        let isChange = true;
        let text: any = new Number(codeValue)
        const numNext = text.valueOf()
        if (typeof(numNext) === 'number') {
            if (numNext === 0 || isNaN(numNext)) {
                text = null; 
            } else {
                text = text.toString()
            }
        } else {
            text = null;   
        } 

        if (cellData.override === text) {
            // override数据没发生个变化
            isChange = false;
        }
    
        // override数据没发生个变化，对应后面月份数据自动改变
        for (let i=col  ; i<secondHeader.length;) {
            const cellData = rowData[dataKeys[i]] as MsrpData; 
            const itemData = {
                id: cellData.id ,
                overrideValue: text,
                nstGroupName: rowData.nstGroupName,
                nstGroup: rowData.nstGroup,
                typeClass: rowData.typeClass,
                brand: rowData.brand,
                month: monthMaps[key]
            };

            const ind = saveData.findIndex(item => {
                return item.id === cellData.id
            })

            // 当前数据不存在于saveData则存入saveData 或 和原数据不相等
            if (ind === -1 && isChange) {
                saveData.push(itemData)
                // 编辑过的Override单元格变成红色字体
                sheet.setStyle(row, i, fontColor);
            } else if (ind !== -1 && isChange){
                // 当前数据存在于saveData且变化则修改该数据
                saveData[ind].overrideValue = itemData.overrideValue;       
                sheet.setStyle(row, i, fontColor);      
            } else if (ind !== -1 && isChange === false){
                // 在saveData中，但是和原数据相比未变化,删除
                saveData.splice(ind, 1)
                // 回复原有样式
                if (cellData.status !== '2') {
                    sheet.setStyle(row, i, fontColor);
                } else {
                    sheet.setStyle(row, i, fontBlackColor);
                }       
            } else {
                // 没修改,也不存在于saveData中(相当于从来没编辑过)
                if (cellData.status !== '2' && cellData.override) {
                    sheet.setStyle(row, i, fontColor);
                } else {
                    sheet.setStyle(row, i, fontBlackColor);
                } 
                return
            }  

            // 当前行对应修改月份后面月份的Override都变
            sheet.setValue(row, i + 2, text, GC.Spread.Sheets.SheetArea.viewport);

            // 数据格式设置（放在setStyle前面不生效）
            // sheet.setFormatter(row, i, "#,##0.00", spreadNS.SheetArea.viewport);
            sheet.setFormatter(row, i, "#,##", spreadNS.SheetArea.viewport);

            i += 2;
        }
    }

    spread.bind(spreadNS.Events.ValueChanged, function (e: any, args: any) {
        setData(args, false)
    });

    sheet.bind(GC.Spread.Sheets.Events.RangeChanged, function (sender: any, args: any) {
        if (args.action == GC.Spread.Sheets.RangeChangedAction.clear) {
            args.changedCells.forEach((arg: any) => {
                setData({...arg, newValue: null}, false)
            })
        }
    })

    let isCopy = false;

    sheet.bind(GC.Spread.Sheets.Events.ClipboardPasting, function (e: any, info: any) {
        isCopy = true;
    });

    sheet.bind(spreadNS.Events.RangeChanged, (e: any, info: any) => {
        // 只处理粘贴进来的
        if (isCopy === true) {
            info.changedCells.forEach((item: any) => {
                setData(item, true )
            })
            isCopy = false
        }
    }) 

    const option = {
        allowSelectLockedCells: true,
        allowSelectUnlockedCells: true,
        allowFilter: true,
        allowSort: true,
        allowResizeRows: false,
        allowResizeColumns: true,
        allowEditObjects: false,
        allowDragInsertRows: true,
        allowDragInsertColumns: true,
        allowInsertRows: false,
        allowInsertColumns: false,
        allowDeleteRows: false,
        allowDeleteColumns: false
    };
    sheet.options.protectionOptions = option;

    spread.resumePaint();
}

// 计算表格的高度
export const usetableHeight = (id: string, idTop?: string) => {
    const tablePosition = reactive<TablePosition>({tableHeight: 200});
    const resizeHandler = (): void => {
        try {
            // 获取body的高度
            const clientHeight: number = document.body.clientHeight;
            const tableOffsetTop: number = (document.querySelector(id) as HTMLDivElement).getBoundingClientRect().top;
            
            tablePosition.tableHeight = clientHeight - tableOffsetTop - 20;
            (document.querySelector(id) as HTMLDivElement).style.height = tablePosition.tableHeight + 'px'
        } catch (e) {
            console.log(e);
        }
    }
    onMounted(() => {
        resizeHandler();
        window.addEventListener('resize', resizeHandler);
    })

    return toRefs(tablePosition);
}