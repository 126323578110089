import { onMounted, onBeforeUnmount, ref } from "vue";
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css";
import GC from "@grapecity/spread-sheets";
import {
  getSIConfigurationStr,
  ReportSIConfigurationStr,
  ReportVehicleResponse,
} from "@/API/report";
interface HeaderProps {
  name: string;
  size?: number;
}
const headerData = [
  { name: "incentiveCategory", size: 150 },
  { name: "programCategory", size: 150 },
  { name: "specialMark", size: 100 },
  { name: "customerType", size: 120 },
  { name: "janModel", size: 150 },
  { name: "janProgramContent", size: 150 },
  { name: "febModel", size: 150 },
  { name: "febProgramContent", size: 150 },
  { name: "marModel", size: 150 },
  { name: "marProgramContent", size: 150 },
  { name: "aprModel", size: 150 },
  { name: "aprProgramContent", size: 150 },
  { name: "mayModel", size: 150 },
  { name: "mayProgramContent", size: 150 },
  { name: "junModel", size: 150 },
  { name: "junProgramContent", size: 150 },
  { name: "julModel", size: 150 },
  { name: "julProgramContent", size: 150 },
  { name: "augModel", size: 150 },
  { name: "augProgramContent", size: 150 },
  { name: "sepModel", size: 150 },
  { name: "sepProgramContent", size: 150 },
  { name: "octModel", size: 150 },
  { name: "octProgramContent", size: 150 },
  { name: "novModel", size: 150 },
  { name: "novProgramContent", size: 150 },
  { name: "decModel", size: 150 },
  { name: "decProgramContent", size: 150 },
];
const headerDataFA = [
  { name: "incentiveCategory", size: 150 },
  { name: "programCategory", size: 150 },
  { name: "specialMark", size: 100 },
  { name: "customerType", size: 120 },
  { name: "faConditions", size: 100 },
  { name: "janModel", size: 150 },
  { name: "janProgramContent", size: 150 },
  { name: "febModel", size: 150 },
  { name: "febProgramContent", size: 150 },
  { name: "marModel", size: 150 },
  { name: "marProgramContent", size: 150 },
  { name: "aprModel", size: 150 },
  { name: "aprProgramContent", size: 150 },
  { name: "mayModel", size: 150 },
  { name: "mayProgramContent", size: 150 },
  { name: "junModel", size: 150 },
  { name: "junProgramContent", size: 150 },
  { name: "julModel", size: 150 },
  { name: "julProgramContent", size: 150 },
  { name: "augModel", size: 150 },
  { name: "augProgramContent", size: 150 },
  { name: "sepModel", size: 150 },
  { name: "sepProgramContent", size: 150 },
  { name: "octModel", size: 150 },
  { name: "octProgramContent", size: 150 },
  { name: "novModel", size: 150 },
  { name: "novProgramContent", size: 150 },
  { name: "decModel", size: 150 },
  { name: "decProgramContent", size: 150 },
];
const useSpreadTableSIConfirgurationReport = (queryParams: any) => {
  const spinFlag = ref<boolean>(false);
  const dataRes = ref<ReportSIConfigurationStr[]>([]);
  const workbook: GC.Spread.Sheets.Workbook[] = [];
  const workbookFA: GC.Spread.Sheets.Workbook[] = [];
  // 筛选
  const setFilter = (sheet: GC.Spread.Sheets.Worksheet, len: number) => {
    const range = new GC.Spread.Sheets.Range(-1, 0, -1, len);
    const rowFilter = new GC.Spread.Sheets.Filter.HideRowFilter(range);
    sheet.rowFilter(rowFilter);
    rowFilter.filterDialogVisibleInfo({
      sortByColor: false,
    });
  };

  // 头部初始化
  const initHeader = (
    spread: GC.Spread.Sheets.Workbook,
    sheet: GC.Spread.Sheets.Worksheet,
    firstLeft: string[],
    secondLeft: string[],
    columnCount: number,
    backColors: string
  ) => {
    //挂起
    sheet.suspendPaint();

    // 重复的12个月
    const firstRight = [
      "Jan.",
      "Jan.",
      "Feb.",
      "Feb.",
      "Mar.",
      "Mar.",
      "Apr.",
      "Apr.",
      "May.",
      "May.",
      "Jun.",
      "Jun.",
      "Jul.",
      "Jul.",
      "Aug.",
      "Aug.",
      "Sep.",
      "Sep.",
      "Oct.",
      "Oct.",
      "Nov.",
      "Nov.",
      "Dec.",
      "Dec.",
    ];
    // 12个'Model', 'Program Content'
    const secondRight = [
      "Model",
      "Program Content",
      "Model",
      "Program Content",
      "Model",
      "Program Content",
      "Model",
      "Program Content",
      "Model",
      "Program Content",
      "Model",
      "Program Content",
      "Model",
      "Program Content",
      "Model",
      "Program Content",
      "Model",
      "Program Content",
      "Model",
      "Program Content",
      "Model",
      "Program Content",
      "Model",
      "Program Content",
    ];
    const headerArr: string[][] = [
      [...firstLeft, ...firstRight],
      [...secondLeft, ...secondRight],
    ];

    // 设置表头为两行
    sheet.setRowCount(2, GC.Spread.Sheets.SheetArea.colHeader);
    // 设置列数
    sheet.setColumnCount(columnCount, GC.Spread.Sheets.SheetArea.colHeader);
    // 设置表头的字体
    const style = new GC.Spread.Sheets.Style();
    style.font = "700 15px Calibri";
    sheet.setStyle(0, 0, style, GC.Spread.Sheets.SheetArea.colHeader);
    // 给表头单元格赋值
    for (let row = 0; row < headerArr.length; row++) {
      for (let col = 0; col < headerArr[row].length; col++) {
        // 给表头单元格赋值
        sheet.setValue(
          row,
          col,
          headerArr[row][col],
          GC.Spread.Sheets.SheetArea.colHeader
        );
        // 设置表头单元格的背景色
        sheet
          .getCell(row, col, GC.Spread.Sheets.SheetArea.colHeader)
          .backColor(backColors);
        // 设置行高
        sheet.setRowHeight(row, 30, GC.Spread.Sheets.SheetArea.colHeader);
      }
    }
    // 自动合并
    const ranges = new GC.Spread.Sheets.Range(-1, -1, -1, -1);
    sheet.autoMerge(
      ranges,
      GC.Spread.Sheets.AutoMerge.AutoMergeDirection.row,
      GC.Spread.Sheets.AutoMerge.AutoMergeMode.free,
      GC.Spread.Sheets.SheetArea.colHeader
    );
    sheet.autoMerge(
      ranges,
      GC.Spread.Sheets.AutoMerge.AutoMergeDirection.column,
      GC.Spread.Sheets.AutoMerge.AutoMergeMode.free,
      GC.Spread.Sheets.SheetArea.rowHeader
    );

    // 冻结，固定前面4列
    sheet.frozenColumnCount(firstLeft.length);
    // 筛选
    setFilter(sheet, firstLeft.length);

    spread.resumePaint();
  };
  // Spread初始化设置
  const initSpread = (
    spread: GC.Spread.Sheets.Workbook,
    sheet: GC.Spread.Sheets.Worksheet,
    columnCount: number
  ) => {
    sheet.suspendPaint();

    // sheet是否显示
    spread.options.tabNavigationVisible = false;
    spread.options.tabStripVisible = false;

    // 隐藏行头
    sheet.options.rowHeaderVisible = false;

    // 要设置允保护，不允许的才能生效, 不可编辑
    // sheet.options.isProtected = true;
    // 设置行高
    const style = sheet.getDefaultStyle();
    sheet.defaults.rowHeight = 50;
    // 设置文字垂直居中
    style.vAlign = GC.Spread.Sheets.VerticalAlign.center;
    // 设置换行
    sheet.getCell(-1, -1).wordWrap(true);
    // 设置列数
    sheet.setColumnCount(columnCount);

    spread.resumePaint();
  };

  // 绑定数据
  const bindDataSpread = (
    spread: GC.Spread.Sheets.Workbook,
    sheet: GC.Spread.Sheets.Worksheet,
    data: ReportVehicleResponse[],
    headers: HeaderProps[]
  ) => {
    // 设置行数
    sheet.setRowCount(data.length, GC.Spread.Sheets.SheetArea.viewport);
    // 自动生成绑定列
    sheet.autoGenerateColumns = false;
    // 绑定数据源
    sheet.setDataSource(data);
    // 将数据字段绑定到指定列size,formatter,cellType
    sheet.bindColumns(headers);
    // for (let row = 0; row < data.length; row++) {
    //     sheet.autoFitRow(row)
    // }
  };
  // 挂载到html上
  const ssHostInit = (
    spread: GC.Spread.Sheets.Workbook,
    sheet: GC.Spread.Sheets.Worksheet,
    firstLeft: string[],
    secondLeft: string[],
    data: ReportVehicleResponse[],
    columnCount: number,
    headers: HeaderProps[],
    backColors: string
  ) => {
    initHeader(spread, sheet, firstLeft, secondLeft, columnCount, backColors);
    initSpread(spread, sheet, columnCount);
    bindDataSpread(spread, sheet, data, headers);
    // spinFlag.value = false
  };
  const init = (data: ReportSIConfigurationStr[]) => {
    if (workbook) {
      for (let i = 0; i < workbook.length; i++) {
        workbook[i].destroy();
        workbookFA[i].destroy();
      }
    }

    const secondLeft = [
      "Incentive Category",
      "Program Category",
      "Special Mark",
      "Customer Type",
    ];
    const secondLeftFA = [
      "Incentive Category",
      "Program Category",
      "Special Mark",
      "Customer Type",
      "Conditions",
    ];
    for (let i = 0; i < data.length; i++) {
      // 4个重复'BU TypeClass Code'
      const firstLeft = [
        `${data[i].buName}  ${data[i].typeClassCode}`,
        `${data[i].buName}  ${data[i].typeClassCode}`,
        `${data[i].buName}  ${data[i].typeClassCode}`,
        `${data[i].buName}  ${data[i].typeClassCode}`,
      ];
      const spread = new GC.Spread.Sheets.Workbook(
        document.getElementById(`ssHost${i}`) as HTMLElement
      );
      workbook.push(spread);
      const sheets = spread.getActiveSheet();
      ssHostInit(
        spread,
        sheets,
        firstLeft,
        secondLeft,
        data[i].exclude_fa_form,
        28,
        headerData,
        "#bfbfbf"
      );
      // 5个重复'BU TypeClass Code'
      const firstLeftFA = [
        `${data[i].buName}  ${data[i].typeClassCode}`,
        `${data[i].buName}  ${data[i].typeClassCode}`,
        `${data[i].buName}  ${data[i].typeClassCode}`,
        `${data[i].buName}  ${data[i].typeClassCode}`,
        `${data[i].buName}  ${data[i].typeClassCode}`,
      ];
      const spreadFA = new GC.Spread.Sheets.Workbook(
        document.getElementById(`ssHostFA${i}`) as HTMLElement
      );
      workbookFA.push(spreadFA);
      const sheetsFA = spreadFA.getActiveSheet();
      ssHostInit(
        spreadFA,
        sheetsFA,
        firstLeftFA,
        secondLeftFA,
        data[i].fa_fa_buydown_form,
        29,
        headerDataFA,
        "#D4E6F9"
      );
    }
    spinFlag.value = false;
  };

  // 获得接口查询数据
  const getFetch = (queryParams: any) => {
    spinFlag.value = true;
    console.log("queryParams", queryParams);
    getSIConfigurationStr({ params: queryParams }).then(
      (res: ReportSIConfigurationStr[]) => {
        dataRes.value = res;
        setTimeout(() => {
          init(res);
        }, 1);
      }
    );
  };
  onMounted(() => {
    getFetch(queryParams);
  });

  onBeforeUnmount(() => {
    for (let i = 0; i < workbook.length; i++) {
      workbook[i].destroy();
      workbookFA[i].destroy();
    }
  });
  return {
    getFetch,
    dataRes,
    spinFlag,
  };
};
export default useSpreadTableSIConfirgurationReport;
