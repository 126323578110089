import { fetchGet, fetchPost, fetchPostPic, fetchGetMess } from './index'
import { string } from 'vue-types';
// import type { InterestInfoItem, InterestInfo, InterestMonthTotalAmount } from '@/views/Closing/type'
export interface HeaderProps {
  name: string;
  formal?: string;
}
export interface ReportProgramResponse {
  firstRight: HeaderProps[];
  secondRight: HeaderProps[];
  tableData: string[][];
}
export interface ReportVehicleResponse {
  [key: string]:  string | number | null | boolean;
}
export interface ReportSIConfiguration{
  bu: string;
  buName: string;
  typeClassCode: string;
  exclude_fa_form: string[][];
  fa_fa_buydown_form: string[][];
}
export interface ReportSIConfigurationStr{
  bu: string;
  buName: string;
  typeClassCode: string;
  exclude_fa_form: ReportVehicleResponse[];
  fa_fa_buydown_form: ReportVehicleResponse[];
}
// 获取structure列表信息
export const getStructureList = (): Promise<any> => {
  return fetchGet('/pcapi/report/structure/tree')
}
// 批量保存接口
export const saveTree = (params = {}): Promise<any> => {
  return fetchPost('/pcapi/report/structure/upsert', params)
}
// 获取item选项列表数据
export const getItemList = (): Promise<any> => {
  return fetchGet('/pcapi/report/structure/validReportItems');
}

// 添加item选项接口
export const postItem = (params = {}): Promise<any> => {
  return fetchPost('/pcapi/report/structure/addReportItem', params)
}

// 解绑item选项
export const unBindItem = (params: any): Promise<any> => {
  return fetchGet('/pcapi/report/structure/unbindReportStructItem/' + params.sid + '/' + params.itemId);
}

// 绑定item选项
export const BindItem = (params = {},paramsId=null,): Promise<any> => {
  return fetchGet('/pcapi/report/structure/bindReportStructItem/' + paramsId ,params);
}

// 基于program维度的budget报表
export const controllingReportProgram = (params = {}): Promise<ReportProgramResponse> => {
  return fetchGet('/bgapi/controlling/report/program', params);
}

// 基于program维度的budget报表
export const controllingReportVehicle = (params = {}): Promise<ReportVehicleResponse[]> => {
  return fetchGet('/bgapi/controlling/report/vehicle', params);
}

export const salesReport = (params = {}): Promise<any> => {
  return fetchPost('/bgapi/sales/report', params)
}
// [OASIS-8742] 获取 SI Configuration 报告
export const getSIConfiguration = (params = {}): Promise<ReportSIConfiguration[]> => {
  return fetchGet('/pgapi/report/get_si_configuration', params);
}
// [OASIS-8742] 获取 SI Configuration 报告
export const getSIConfigurationStr = (params = {}): Promise<ReportSIConfigurationStr[]> => {
  return fetchGet('/pgapi/report/get_si_configuration_str', params);
}


